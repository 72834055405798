import request from '@/utils/request.js'

// 获取小程序用户列表
export function appleUserListApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/user',
    method: 'post',
    data: data
  })
}


// 新增小程序用户
export function appleUserAddApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/create',
    method: 'post',
    data: data
  })
}

// 更新小程序用户
export function appleUserUpdateApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/update.u',
    method: 'post',
    data: data
  })
}

// 删除小程序用户 ids:[]  menuType: 操作状态：delete 删除 push 推送 download 下载
export function appleUserDeleteApi(data) {
  return request({
    // url: '/doftec/login2',
    url: '/doftec/admin-user/action',
    method: 'post',
    data: data
  })
}


// 获取小程序用户详情
export function appleUserDetailApi(params) {
  return request({
    url: '/doftec/admin-user/info',
    method: 'get',
    params: params
  })
}
// 获取小程序用户绑定企业列表
export function appleUserCompanyApi(uid, params) {
  return request({
    url: '/api/company/temp/list/uid/'+uid,
    method: 'get',
    params:params
  })
}

// 获取小程序用户参与过的问卷
export function appleUserSurveyApi(uid, data) {
  return request({
    url: '/api/survey/list/post/uid/'+uid,
    method: 'post',
    data: data
  })
}

// 小程序用户绑定的企业解除绑定， id数据主键，val是-1 取消绑定，0表示启用
export function appleUserStatusApi(id, val, data) {
  return request({
    url: '/api/company/temp/set/'+id+'/status/'+val,
    method: 'post',
    data:data
  })
}