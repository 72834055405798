<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="姓名" prop="name">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.name"
                        placeholder="请输入"
                         clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phoneNumber">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.phoneNumber"
                        placeholder="请输入"
                         clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="openId" prop="openid">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.openid"
                        placeholder="请输入"
                         clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="uid" width="55" label="#">
                </el-table-column>
                <el-table-column prop="name" label="姓名"  >
                </el-table-column>
                <el-table-column
                    prop="phoneNumber"
                    label="手机"
                     
                >
                </el-table-column>
                <!-- <el-table-column
                    prop="release"
                    label="企业"
                     
                >
                </el-table-column> -->
                <el-table-column
                    prop="openid"
                    label="openId"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                     
                >
                    <template slot-scope="scope">{{
                        scope.row.status | isYes
                    }}</template>
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="注册日期"
                     
                >
                    <template slot-scope="scope">{{
                        Number(scope.row.time + '000') | dayTimejs
                    }}</template>
                </el-table-column>
                <el-table-column
                    prop="timeAdd"
                    label="最后登录"
                     
                >
                    <template v-if="scope.row.timeAdd" slot-scope="scope">{{
                        scope.row.timeAdd | dayjs
                    }}</template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="editClick(scope.row, false)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="previewClick(scope.row)"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form label-width="88px" :model="formLabelAlign" ref="ruleForm" :rules="rules">
                    <el-form-item label="openid" prop="openid">
                        <el-input
                            v-model="formLabelAlign.openid"
                            :disabled="true"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="unionid" prop="unionid">
                        <el-input
                            v-model="formLabelAlign.unionid"
                            :disabled="true"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="姓名：" prop="name">
                        <el-input
                            v-model="formLabelAlign.name"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="手机号码：" prop="phoneNumber">
                        <el-input
                            v-model="formLabelAlign.phoneNumber"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="状态码" prop="status">
                        <el-radio-group v-model="formLabelAlign.status">
                            <el-radio :label="1">可用</el-radio>
                            <el-radio :label="0">不可用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model="formLabelAlign.info"
                            :disabled="disabled"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span v-if="!disabled" slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick('ruleForm')"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    appleUserListApi,
    appleUserUpdateApi,
    appleUserDeleteApi,
} from '@/api/appletUser'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            title: '',
            dialogVisible: false,
            typeList: [
                {
                    label: '外贸调研',
                    value: '外贸调研',
                },
                {
                    label: '商务部标准版本',
                    value: '商务部标准版本',
                },
                {
                    label: '山东兼容版',
                    value: '山东兼容版',
                },
            ],
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
            disabled: false,
            rules:{
                status: [
                    { required: true, message: '请选择状态码', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                phoneNumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
            				{ pattern: /^1\d{10}$/, trigger: "blur", message: "手机号格式错误"  }
                ],
            }
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.appleUserListFun()
    },
    methods: {
        // 获取小程序列表
        async appleUserListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                orderStr:'time desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await appleUserListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
        },
        // 批量删除
        async allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.uid)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await appleUserDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.appleUserListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.appleUserListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.appleUserListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.appleUserListFun(parm.currentPage, parm.pageSize)
        },
        // 预览
        previewClick(row) {
            this.$router.push({
                path: '/smallProgramUser/previewSmall',
                query: {
                    id: row.uid,
                },
            })
        },
        // 编辑模板
        editClick(row, disable) {
            // console.log('row', row)
            this.dialogVisible = true
            this.disabled = disable
            if (disable) {
                this.title = '查看'
            } else {
                this.title = '编辑'
            }
            this.formLabelAlign = row
        },
        // 编辑提交
        async editSubmitClick(formName) {
             this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    delete this.formLabelAlign.timeAdd
                    var res = await appleUserUpdateApi(this.formLabelAlign)
                    if (res.code === 200) {
                        this.dialogVisible = false
                        this.$message({
                            type: 'success',
                            message: res.message,
                        })
                        this.appleUserListFun()
                    }
                    
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
